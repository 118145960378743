import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload"; //图片懒加载
import VueClipboard from "vue-clipboard2"; //复制文本

import Rem from "@/utils/rem.js"; //px转rem文件

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import Vant from "vant";
import "vant/lib/index.css";
import MySocket from "./network/socket";
Vue.use(MySocket);

Rem(1920, 1920, 325);
Vue.config.productionTip = false;

//element
Vue.use(ElementUI);

//vant
Vue.use(Vant);

//复制文本
Vue.use(VueClipboard);

Vue.use(VueLazyload, {
  loading: require("@/assets/images/loading-svg/loading-bars.svg"),
  error: require("@/assets/images/other/none.jpg"),
});
import SingleBox from "@/components/common/Pages/SingleBox";
import OddsList from "@/components/common/Pages/OddsList";
import Cprice from "@/components/common/Cprice";
import Cint from "@/components/common/Cint";
import Cpointicon from "@/components/common/Cpointicon"
import Hotprice from "@/components/common/Hotprice"
// 分页组件
import Pagination from "@/components/changepage/index.vue";
Vue.component("MySingleBox", SingleBox);
Vue.component("MyOddsList", OddsList);
Vue.component("Cprice", Cprice);
Vue.component("Cint", Cint);
Vue.component("Hotprice", Hotprice);
Vue.component("Cpoint", Cpointicon);
Vue.component("Pagination", Pagination);
// 解决定位显示为顶部问题
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
// 正式环境清除所有console.log
if (process.env.NODE_ENV === "production") {
  if (window) {
    window.console.log = function () {};
  }
}
// 控制缩放
document.addEventListener(
  "keydown",
  function (event) {
    if (
      (event.ctrlKey === true || event.metaKey === true) &&
      (event.keyCode === 61 ||
        event.keyCode === 107 ||
        event.keyCode === 173 ||
        event.keyCode === 109 ||
        event.keyCode === 187 ||
        event.keyCode === 189)
    ) {
      event.preventDefault();
    }
  },
  false
);
document.addEventListener(
  "mousewheel",
  function (e) {
    e = e || window.event;
    // @ts-ignore
    if ((e.wheelDelta && event.ctrlKey) || e.detail) {
      event.preventDefault();
    }
  },
  {
    capture: false,
    passive: false,
  }
);
//PC跳H5
var sUserAgent = navigator.userAgent.toLowerCase();
const flag = sUserAgent.match(
  /(ipod|iphone os|midp|ucweb|android|windows ce|windows mobile)/i
);
console.log(process.env.NODE_ENV === "production" ? "正式环境" : "test环境");
console.log(flag ? "移动端" : "PC端");
function checkUserAgent() {
  if (process.env.NODE_ENV === "production") {
    if (flag) {
      location.href = "https://m.tmskins.com";
      // location.href = location.href.replace(/(https:\/\/)([^\/]+)/, "$1m.$2");
    } else {
      new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount("#app");
    }
  } else {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
}
checkUserAgent();
