const mp3Start = new Audio(require("@/assets/audios/openb.mp3")); //开箱过程声音
const mp3End = new Audio(require("@/assets/audios/boom.mp3")); //开箱结束声音
export default {
  // 设置全局站点信息
  setGlobalInfo(state, val) {
    state.global_info = val;
  },
  //设置用户信息
  User(state, u) {
    state.user = u;
  },
  ChangeAudioStatus(store, val) {
    store.soundStatus = val;
    if (val) {
      mp3Start.load();
      mp3End.load();
      store.mp3Start = mp3Start;
      store.mp3End = mp3End;
    } else {
      store.mp3Start = null;
      store.mp3End = null;
    }
  },

  //加入房间信息
  AddInformation(store, val) {
    store.AddInformation = val;
  },

  GetIndividualsShow(state, val) {
    state.IndividualsShow = val;
  },

  //回合
  GetroundNum(state, val) {
    state.roundNum = val;
  },

  //开始
  GetVsKaiShi(state, val) {
    state.VsKaiShi = val;
  },

  //开始对战数据
  GetKaiShiVsData(state, val) {
    state.KaiShiVsData = val;
  },

  //结束对战数据
  GetJieShuVsData(state, val) {
    state.JieShuVsData = val;
  },

  //结束
  GetVsJieShu(state, val) {
    state.VsJieShu = val;
  },

  //注册弹框
  GetRegisterShow(state, val) {
    state.RegisterShow = val;
  },

  //登录弹框
  GetSignInShow(state, val) {
    state.SignInShow = val;
  },
  //高光时刻弹窗
  SetPopupHighLightShow(state, obj = {}) {
    state.popupHighLightShow = obj.val;
    state.popupHighLightId = obj.id;
  },
  //退出登录
  Leave(state) {
    state.user.state = false;
    state.user = {};
    window.localStorage.clear();
    window.sessionStorage.clear();
  },
};
