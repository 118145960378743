import axios from "./axios";

// 获取福利弹窗消耗数据
export function getrecord() {
  return axios({
    url: `/record/use_record`,
    method: "get",
    closeMsg: true,
  });
}
//注册
export function Register(mobile, password, name, verify, invite) {
  return axios({
    url: "/register",
    method: "post",
    data: {
      mobile,
      pass: password,
      name,
      verify,
      invite,
    },
  });
}
//箱子数据及物品数据
export function Box_detail(params) {
  return axios({
    url: "/box/detail",
    params
  });
}

export function set_inviter(invite_code) {
  return axios({
    url: "/set_inviter",
    method: "post",
    data: {
      invite_code,
    },
  });
}
//发送手机验证码
// is_use：1注册2登陆3修改密码
export function PostSendSms(mobile, is_use) {
  return axios({
    url: "/send_sms",
    method: "post",
    data: {
      mobile,
      is_use,
    },
  });
}

//登录
export function SignIn(mobile, pass, verify = "") {
  if (verify == "") {
    return axios({
      url: "/login",
      method: "post",
      data: {
        mobile,
        pass,
      },
    });
  } else {
    return axios({
      url: "/sms_login",
      method: "post",
      data: {
        mobile,
        verify,
      },
    });
  }
}

//个人信息
export function PersonalInfo() {
  return axios({
    url: "/me",
    closeMsg: true,
  });
}

//设置steam连接
export function SteamUrl(params) {
  return axios({
    url: "/set_steam_url",
    method: "post",
    data: params,
  });
}
//绑定steam连接
export function SteamBind(mobile, verify, steam_data) {
  return axios({
    url: "/steam/bind_mobile",
    method: "post",
    data: {
      mobile,
      verify,
      steam_data,
    },
  });
}

//仓库
export function Storage(page = 1, sort, status = 0, pagesize = 24) {
  return axios({
    url: "/storage",
    params: {
      pagesize,
      page,
      sort,
      status,
    },
  });
}

//提取
export function Extract(data) {
  return axios({
    url: "/extract",
    method: "post",
    data: {
      data,
    },
  });
}

// 金豆列表
export function Beans() {
  return axios({
    url: "/beans",
  });
}

//充值接口
export function Recharge(params) {
  return axios({
    url: "/recharge",
    method: "post",
    data: params,
  });
}

//充值记录
export function Recharge_record(type, page) {
  return axios({
    url: "/bean/change_records",
    params: {
      type,
      page,
    },
  });
}

//Top开箱记录
export function UnpackingRecord(params) {
  return axios({
    url: "/top_history",
    params,
  });
}
//开箱记录
export function BoxUnpackingRecord(params) {
  return axios({
    url: "/box/history",
    params,
  });
}

//热门宝箱
export function GetHotBox() {
  return axios({
    url: "/box/list",
  });
}

// Roll房列表
export function Rooms(status, page = "1") {
  return axios({
    url: "/rooms",
    params: {
      status,
      page,
    },
  });
}

//参加房间记录
export function UserRooms(params) {
  return axios({
    url: "/user/rooms",
    params,
  });
}

//头像上传
export function base64(data) {
  return axios({
    url: "/image/base64",
    method: "post",
    data: {
      data,
    },
  });
}

//头像更新
export function Set_avatar(path) {
  return axios({
    url: "/set_avatar",
    method: "post",
    data: {
      path,
    },
  });
}

//装备类型
export function luckyType() {
  return axios({
    url: "/lucky/type",
  });
}
//磨损程度类型
export function newOldType() {
  return axios({
    url: "/synthesis/dura",
  });
}

//修改密码
export function Reset_password(mobile, password, verify) {
  return axios({
    url: "/reset_password",
    method: "post",
    data: {
      mobile,
      password,
      verify,
    },
  });
}

//站点信息
export function Info() {
  return axios({
    url: "/info",
  });
}

//进入房间盲盒频道
export function Blind_box_channel(client_id) {
  return axios({
    url: "/arena/join-channel",
    method: "post",
    data: {
      client_id,
    },
  });
}

//卡密支付
export function Card_recharge(card) {
  return axios({
    url: "/card-recharge",
    method: "post",
    data: {
      card,
    },
  });
}

//更改用户名称
export function Set_Name(name) {
  return axios({
    url: "/set_name",
    method: "post",
    data: {
      name,
    },
  });
}

//开箱
export function OpenBoxApi(id, num) {
  return axios({
    url: "/box/open",
    method: "post",
    data: {
      id,
      num,
      type: 1,
    },
  });
}

//开红包
export function RedOpen(type, id, key) {
  return axios({
    url: "/red/open",
    method: "post",
    data: {
      type,
      id,
      key,
    },
  });
}

// 审核中的roll列表
export function rollPending(page) {
  return axios({
    url: "/room/pending",
  });
}

//最近获得{uid}
export function getNewlyGetApi(params, page) {
  return axios({
    url: "/record/get_record",
    params: {
      page,
    },
  });
}
//盲盒游戏{uid}
export function getNewlyHighLightApi(params) {
  return axios({
    url: "/record/open_record",
    params,
  });
}
//近期表现{uid}
export function getPerformanceApi(params) {
  return axios({
    url: "/record/top_days",
    params,
  });
}
// 近期奖励
export function getawards_recordsApi(page) {
  return axios({
    url: "/bean/awards_records",
    params: {
      page,
    },
  });
}
// 获得类型统计数据
export function getTypedata(box_id, num) {
  return axios({
    url: "/record/category",
    params: {
      box_id,
      num,
    },
  });
}
// 实名认证
export function checkNameAndCardId(params) {
  return axios({
    url: "/true_name",
    data: params,
    method: "post",
  });
}

// 获得掉落统计数据
export function getStatisticsData(box_id, num) {
  return axios({
    url: "/record/contains",
    params: {
      box_id,
      num,
    },
  });
}
// 获得掉落统计数据
export function getArticleData() {
  return axios({
    url: "/article",
  });
}
// 根据id获得文章数据
export function getDetailData(id) {
  return axios({
    url: "/article/detail",
    params: {
      id,
    },
  });
}
// 获得用户充值和取回数据
export function getrechargeinfoData() {
  return axios({
    url: "/user/info",
  });
}
// 获得主播表格数据
export function getCommissionApi(params) {
  return axios({
    url: "/record/commission",
    data: params,
    method: "post",
  });
}
// 合成装备待选择装备
export function fusionGoodListApi(params) {
  return axios({
    url: "/synthesis/list",
    data: params,
    method: "post",
  });
}
// 合成装备
export function fusionApi(params) {
  return axios({
    url: "/synthesis",
    data: params,
    method: "post",
  });
}
// 弹药合成前检查
export function ammunitionCheckApi(params) {
  return axios({
    url: "/ammunition/check_skins_price",
    data: params,
    method: "post",
  });
}
// 弹药合成
export function ammunitionOpenApi(params) {
  return axios({
    url: "/ammunition/open",
    data: params,
    method: "post",
  });
}
// 获得熔炼记录数据
export function getsynthesisData(params) {
  return axios({
    url: "/synthesis/records",
    params,
  });
}
// 获得弹药记录数据
export function getammunitionData(params) {
  return axios({
    url: "/ammunition/records",
    params,
  });
}
// 获得积分和福利列表箱子type:1积分type:4福利
export function getpointData(params) {
  return axios({
    url: "/welfare",
    params,
  });
}
// 积分开箱
export function pointOpenApi(params) {
  return axios({
    url: "/welfare/open",
    data: params,
    method: "post",
  });
}
// Cdkey开箱
export function cdkeyOpenApi(params) {
  return axios({
    url: "/welfare/cdk_open",
    data: params,
    method: "post",
  });
}
export function pointlistApi(params) {
  return axios({
    url: "/score_records",
    data: params,
    method: "post",
  });
}
// 转盘列表
export function getturntableApi(params) {
  return axios({
    url: "/turntable/list",
    data: params,
    method: "post",
  });
}
// 转盘详情
export function getturntabledetailApi(params) {
  return axios({
    url: "/turntable/detail",
    data: params,
    method: "post",
  });
}
// 参与转盘
export function jointurntableApi(params) {
  return axios({
    url: "/turntable/join",
    data: params,
    method: "post",
  });
}
// 上期中奖
export function up_awardApi(params) {
  return axios({
    url: "/turntable/up_award",
    data: params,
    method: "post",
  });
}
//排行榜
export function winRanking(params) {
  return axios({
    url: "/record/win_ranking",
    params,
  });
}
