<template>
  <div class="comp-cprice">
    <div :style="`font-size:${size}rem`" class="price" :class="{'is-weight':isWeight}"><img :style="`width: ${size/10*8}rem;margin-right:${size/4}rem;`" src="@/assets/images/about/user/hotprice.png" alt="">{{ price || 0 }}</div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: [String, Number],
      default: 0
    },
    size: {
      type: [String, Number],
      default: 0.18
    },
    isWeight: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.comp-cprice {
  display: flex;
  align-items: center;
  justify-content: center;

  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(236, 177, 5);
    font-weight: 300;
  }
  .is-weight {
    font-weight: 600;
  }
}
</style>